//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-260:_2952,_2180,_9800,_4312,_8496,_4932,_2872,_6890;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-260')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-260', "_2952,_2180,_9800,_4312,_8496,_4932,_2872,_6890");
        }
      }catch (ex) {
        console.error(ex);
      }